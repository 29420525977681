@import url("https://cdnjs.cloudflare.com/ajax/libs/material-components-web/4.0.0/material-components-web.min.css");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.fullwidth-table-wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
}
.fullwidth-table-wrapper th:last-child,
.fullwidth-table-wrapper td:last-child {
  padding-right: 40px;
}
.mdl-data-table {
  border: none !important;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 44px;
  min-height: 150px;
}

.dataTables_empty {
  vertical-align: middle;
  text-align: center;
}
div.dataTables_scrollHeadInner {
  width: 100% !important;
}
div.dataTables_scrollBody table {
  margin-top: 0;
}
div.dataTables_scrollBody table thead {
  display: none;
}
div.dataTables_wrapper {
  width: 100% !important;
  overflow: auto;
  padding-bottom: 80px;
  position: relative;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
  right: 40px;
  top: 14px;
  position: absolute;
}
div.dataTables_wrapper div.dataTables_filter label {
  position: relative;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  width: 100%;
  padding-left: 30px;
  font-size: 1rem;
  height: 40px;
}
div.dataTables_wrapper div.dataTables_filter input:focus {
  outline-offset: 0;
  outline: none;
}
div.dataTables_wrapper div.dataTables_filter label .search-icon {
  position: absolute;
  left: 8px;
  top: -3px;
  z-index: 9;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 10px;
  white-space: break-spaces;
  position: absolute;
  bottom: 25px;
  left: 30px;
  color: #999;
  font-size: 0.7rem;
  width: 50%;
  line-height: 1.3em !important;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: calc(100% - 120px);
  text-align: center;
  top: 121px;
  background: #fff;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 990;
}
div.dataTables_wrapper
  div#permission-management-table_processing.dataTables_processing {
  top: 95px;
}
div#edit-groups-table_wrapper div.dataTables_processing {
  top: 95px;
}
div.dataTables_wrapper span.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 80px;
}
div.dataTables_wrapper div.dataTables_paginate {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 24px;
}
div.dataTables_wrapper div.dataTables_paginate a.paginate_button {
  cursor: pointer;
  height: 40px;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_paginate span a.paginate_button {
  line-height: 40px;
  padding: 0 8px;
  color: #999 !important;
}
div.dataTables_wrapper div.dataTables_paginate span a.paginate_button.current {
  color: #000 !important;
}
div.dataTables_wrapper div.mdc-grid.dt-table {
  padding-top: 0;
  padding-bottom: 0;
}
div.dataTables_wrapper div.mdc-grid.dt-table > div.mdc-cell {
  margin-top: 0;
  margin-bottom: 0;
}
div.dataTables_wrapper table.mdc-data-table__table {
  table-layout: auto;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_asc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_desc {
  padding-right: 30px;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th:active {
  outline: none;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_asc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_desc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting {
  cursor: pointer;
  position: relative;
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:after,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:before,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:after {
  position: absolute;
  display: block;
  opacity: 0.3;
  right: 1em;
  line-height: 9px;
  font-size: 0.9em;
}
table.dataTable thead .sorting.select-checkbox:before,
table.dataTable thead .sorting_asc.select-checkbox:before,
table.dataTable thead .sorting_desc.select-checkbox:before,
table.dataTable thead .sorting_asc_disabled.select-checkbox:before,
table.dataTable thead .sorting_desc_disabled.select-checkbox:before {
  content: "" !important;
}
table.dataTable thead .sorting.select-checkbox:after,
table.dataTable thead .sorting_asc.select-checkbox:after,
table.dataTable thead .sorting_desc.select-checkbox:after,
table.dataTable thead .sorting_asc_disabled.select-checkbox:after,
table.dataTable thead .sorting_desc_disabled.select-checkbox:after {
  content: "" !important;
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:before,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:before {
  bottom: 50%;
  content: "\25B4";
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:after,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:after {
  top: 50%;
  content: "\25BE";
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after {
  opacity: 0.8;
}
div.dataTables_length {
  display: none;
}
div.dataTables_wrapper div.mdc-layout-grid {
  margin: 0px;
  padding: 5px;
}
table.dataTable thead {
  border-bottom: 1px solid #ddd;
}
table.dataTable thead th,
table.dataTable tbody td {
  display: table-cell !important;
}
table.dataTable thead tr th,
table.dataTable thead tr td,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #bbb;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.3em;
  text-align: left;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  top: 50%;
  display: block;
  opacity: 0.3;
  font-size: 0.9em;
  margin-top: -7px;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  font-family: "Material Icons";
  right: 3.2em;
  content: "arrow_upward";
  -webkit-font-feature-settings: "liga";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: "Material Icons";
  right: 2.5em;
  content: "arrow_downward";
  -webkit-font-feature-settings: "liga";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
table.dataTable tbody tr {
  border-bottom: 1px solid #ddd;
  transition: 0.2s all;
}
table.dataTable tbody tr:nth-child(2n-1) {
  background-color: #fafafa;
}
table.dataTable tbody tr:hover {
  background-color: #f7f7f7;
}
table.dataTable tbody tr td {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.6em;
  padding-right: 20px;
}
table.dataTable tbody tr td.datatable-data-width-100 {
  max-width: 120px;
}
table.dataTable tbody tr td a {
  text-decoration: none;
  padding: 8px 0;
}
table.dataTable tbody tr td .main-datatable-info {
  font-weight: bold;
}
table.dataTable tbody tr td.editable-field {
  max-width: 250px;
  min-width: 200px;
  padding-right: 0;
}
table.dataTable tbody tr td.editable-field .MuiGrid-grid-xs-9 {
  max-width: 170px;
}
table.dataTable thead > tr > th:last-child.sorting:before,
table.dataTable thead > tr > th:last-child.sorting:before,
table.dataTable thead > tr > th:last-child.sorting_asc:before,
table.dataTable thead > tr > th:last-child.sorting_desc:before,
table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
  right: 4.1em !important;
}
table.dataTable thead > tr > th:last-child.sorting:after,
table.dataTable thead > tr > th:last-child.sorting:after,
table.dataTable thead > tr > th:last-child.sorting_asc:after,
table.dataTable thead > tr > th:last-child.sorting_desc:after,
table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
  right: 3.4em !important;
}
.dataTables_scrollHead {
  padding-right: 30px;
  color: #aaa;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 3.6em;
  text-align: left;
}
.MuiChip-colorPrimary {
  color: #05b0ca !important;
  background-color: #08b9d413 !important;
}
.MuiChip-colorSecondary {
  color: #777 !important;
  background-color: rgba(177, 177, 177, 0.1) !important;
}
.paginate_button.previous,
.paginate_button.next {
  position: relative;
  width: 30px;
  overflow: hidden;
  text-indent: 9999px;
}
.paginate_button.previous::before {
  position: absolute;
  display: block;
  width: 30px;
  height: 40px;
  line-height: 40px;
  font-size: 2em;
  color: #ccc;
  text-indent: -9999px;
  font-family: "Material Icons";
  content: "chevron_left";
  -webkit-font-feature-settings: "liga";
}
.paginate_button.next::before {
  position: absolute;
  display: block;
  width: 30px;
  height: 40px;
  line-height: 40px;
  font-size: 2em;
  color: #ccc !important;
  text-indent: -9999px;
  font-family: "Material Icons";
  content: "chevron_right";
  -webkit-font-feature-settings: "liga";
}
.MuiInputBase-input {
  font-size: 0.8rem !important;
}
.resend-btn-td button {
  margin-bottom: 0 !important;
}
div.dataTables_wrapper div.dataTables_paginate > span {
  display: flex;
  align-items: center;
}

table.dataTable.hover tbody > tr.selected:hover,
table.dataTable.hover tbody > tr > .selected:hover,
table.dataTable.display tbody > tr.selected:hover,
table.dataTable.display tbody > tr > .selected:hover {
  background-color: #aab7d1;
}

table.dataTable th.dt-checkboxes-cell,
table.dataTable td.dt-checkboxes-cell {
  width: 70px !important;
}

table.dataTable tbody td.select-checkbox,
table.dataTable tbody th.select-checkbox {
  position: relative;
  width: 50px !important;
}

table.dataTable tbody td.select-checkbox:before,
table.dataTable tbody td.select-checkbox:after,
table.dataTable tbody th.select-checkbox:before,
table.dataTable tbody th.select-checkbox:after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

table.dataTable tbody td.select-checkbox:before,
table.dataTable tbody th.select-checkbox:before {
  content: " ";
  border: 2px solid #ddd;
  border-radius: 3px;
}

table.dataTable tr.selected td.select-checkbox:after,
table.dataTable tr.selected th.select-checkbox:after {
  font-family: "Material Icons";
  content: "check";
  color: #fff;
  border-radius: 3px;
  background-color: #bbb;
  font-size: 1rem;
  text-align: center;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
  margin-left: 0.5em;
}

table#edit-groups-table tr th:first-child,
table#edit-groups-table tr td:first-child {
  padding-left: 70px;
}

table#edit-groups-table thead th {
  text-align: center;
}

table#edit-groups-table tbody tr td {
  text-align: center;
}

table {
  position: relative !important;
  z-index: 9 !important;
}

div.dataTables_wrapper div#edit-groups-table_filter {
  top: 7px !important;
}

.fullwidth-table-wrapper th:last-child.dataTables_empty,
.fullwidth-table-wrapper td:last-child.dataTables_empty {
  padding-left: 40px;
}

#role-management-table_filter {
  display: none !important;
}

#role-management-table.mdl-data-table .MuiButton-root,
#permission-management-table.mdl-data-table .MuiButton-root {
  padding: 0 !important;
}

table.dataTable tbody td.switch-column {
  display: flex !important;
}

.reset-margin-top-table .mdl-data-table {
  margin-top: 0;
}

.export-button {
  cursor: pointer;
  margin-left: 50px;
  z-index: 90000;
  border: 0;
  height: 30px;
  position: relative;
  width: 30px;
  background: transparent;
  top: 0px;
  left: 0px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover,
.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

input[type="checkbox"],
input.dt-checkboxes {
  width: 20px !important;
  height: 20px !important;
  margin-left: 20px !important;
  display: block;
  filter: grayscale(1);
  margin-right: 0;
}

input[type="checkbox"]::before,
input.dt-checkboxes::before {
  width: 20px !important;
  height: 20px !important;
  display: block;
}

tr.selected input[type="checkbox"]::before,
tr.selected input.dt-checkboxes::before {
  display: block;
  border: none;
}

.dataTables_paginate .ellipsis {
  line-height: 40px;
  padding: 0 8px;
  color: #999 !important;
  margin-top: 30px;
}

div.datatable-with-custom-search {
  position: relative;
}

div.dataTables_wrapper div.dataTables_filter {
  display: none;
}

.custom-search {
  width: 200px;
  right: 10px;
  position: absolute;
  top: -7px;
}

button#searchButton {
  margin-right: 0 !important;
  padding: 0 !important;
  width: 44px;
  min-width: 44px !important;
  height: 44px;
  border-radius: 50%;
}

table.dataTable thead th {
  color: #bbb;
  font-weight: 500;
}

td {
  color: #777;
  font-weight: 400;
  font-size: 0.7rem;
}

td.serial_number {
  color: #000;
  font-weight: 600;
  font-size: 0.8rem;
}

span.status {
  border: 1px solid #ddd;
  padding: 2px 10px;
  min-width: 90px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  font-size: 0.7rem;
}

span.status.registered {
  color: #fac107;
  background-color: #ffda6009;
}

span.status.inactive {
  color: #aaa;
  background-color: #dddddd08;
}

span.status.activated {
  color: #2c3ff3;
  background-color: #2c3ff308;
}

span.nubis-version {
  border: 1px solid #ddd;
  padding: 2px 10px;
  min-width: 90px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  font-size: 0.7rem;
}

span.nubis_pro.nubis-version {
  color: #17acc3;
  background-color: #17acc308;
}

span.nubis_free.nubis-version {
  color: #aaa;
  background-color: #dddddd08;
}

.date-td {
  width: 110px;
}

table#subscriptions-list-table.dataTable tbody tr td {
  padding-right: 10px;
  font-size: 0.7rem !important;
}

.subscription-column {
  padding: 10px 10px 10px 30px !important;
  width: 200px !important;
}

.subscription-column a {
  color: #aaa !important;
  font-size: 0.6rem;
}

.subscription-service {
  font-weight: bold;
  color: #000 !important;
}

.subscription-no {
  color: #aaa !important;
  font-size: 0.6rem;
  padding-left: 2px !important;
  margin-bottom: 2px !important;
}

.date-column {
  width: 100px !important;
}

.users-column {
  overflow: hidden;
  width: 80px !important;
}

.subscription-status span.status {
  margin-right: 6px;
  font-size: 0.675rem;
  padding: 1px 6px;
  min-width: 80px;
}

.subscription-status .MuiSvgIcon-root {
  font-size: 1.2rem !important;
  opacity: 0.5;
  transition: all 0.2s;
}

.subscription-status .MuiSvgIcon-root:hover {
  opacity: 1;
}

.devices-column {
  width: 340px !important;
  padding: 8px !important;
}

table#subscriptions-list-table.dataTable .company-name {
  width: 80px !important;
}

.more {
  display: block;
  text-decoration: underline;
  color: #aaa;
  cursor: pointer;
}

img.service-icon {
  display: inline-block;
  height: 14px;
  margin-right: 5px;
}

.dialog-fullwidth-table-wrap {
  position: relative;
  overflow-y: visible !important;
}

.dialog-fullwidth-table-wrap .custom-search {
  top: -57px;
  right: -20px;
}

.dialog-fullwidth-table-wrap table {
  margin-top: 0;
}

.MuiDialogContent-root.one-column-table-wrap {
  padding: 0 !important;
}

.MuiDialogContent-root div.dataTables_wrapper {
  padding-bottom: 0;
}

.MuiDialogContent-root div.dataTables_wrapper div.dataTables_info,
.MuiDialogContent-root div.dataTables_wrapper div.dataTables_paginate {
  bottom: initial;
  width: 98%;
  position: relative;
}

.MuiDialogContent-root div.dataTables_wrapper div.dataTables_info {
  left: 1%;
  margin: 10px 0;
}

.MuiDialogContent-root div.dataTables_wrapper div.dataTables_paginate {
  right: 1%;
}

.MuiDialogContent-root div.dataTables_wrapper div.dataTables_processing {
  top: 37px;
  height: calc(100% - 65px);
}

.MuiDialogContent-root
  div.dataTables_wrapper
  div.dataTables_processing
  span.loading {
  min-height: 30px;
}

.one-column-table-wrap table thead tr th:first-child,
.one-column-table-wrap table tbody tr td:first-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.subscriptions-td {
  width: 50%;
}

div.dataTables_wrapper #subscriptions-list-table_processing {
  top: 95px;
  height: calc(100% - 95px);
}

div.dataTables_wrapper #subscribe-device-table_processing {
  top: 99px;
  height: calc(100% - 113px);
}

.MuiPopover-paper > .MuiList-root {
  max-height: 50vh;
}

#text-with-reload-dialog-title {
  padding: 0 0 16px !important;
}

table.dataTable.narrow-table tbody tr td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

#store-list-table_wrapper {
  width: calc(100% + 64px) !important;
  margin-left: -32px !important;
  margin-bottom: -64px !important;
  padding-bottom: 60px !important;
  overflow: visible;
}

#store-list-table th:first-child,
#store-list-table td:first-child,
#store-list-table_wrapper th:first-child,
#store-list-table_wrapper td:first-child {
  padding-left: 32px;
}

#store-list-table th:last-child,
#store-list-table td:last-child,
#store-list-table_wrapper th:last-child,
#store-list-table_wrapper td:last-child {
  padding-right: 32px;
}

#store-list-table_wrapper a.paginate_button {
  margin-top: 6px !important;
}

#store-list-table_wrapper div.dataTables_processing {
  top: 47px;
  height: calc(100% - 109px);
}

.store-list-button-wrap .custom-search {
  right: -80px;
  top: -10px;
}

.store-list-button-wrap .custom-search input {
  width: 100px;
}

@media screen and (max-width: 640px) {
  div.dataTables_wrapper span.select-info,
  div.dataTables_wrapper span.select-item {
    margin-left: 0;
    display: block;
  }
}

@media screen and (max-width: 1680px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 30px;
  }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 1.5em !important;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 3.1em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 2.3em !important;
  }
}

@media screen and (max-width: 1440px) {
  table.dataTable thead > tr > th.sorting:before,
  table.dataTable thead > tr > th.sorting_asc:before,
  table.dataTable thead > tr > th.sorting_desc:before,
  table.dataTable thead > tr > th.sorting_asc_disabled:before,
  table.dataTable thead > tr > th.sorting_desc_disabled:before {
    right: 1.2em !important;
  }
  table.dataTable thead > tr > th.sorting:after,
  table.dataTable thead > tr > th.sorting_asc:after,
  table.dataTable thead > tr > th.sorting_desc:after,
  table.dataTable thead > tr > th.sorting_asc_disabled:after,
  table.dataTable thead > tr > th.sorting_desc_disabled:after {
    right: 0.5em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 2.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 2em !important;
  }
}
@media screen and (max-width: 1279px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 30px;
  }
  table.dataTable tbody tr td.editable-field {
    max-width: 200px;
    min-width: 150px;
  }
  table.dataTable thead > tr > th.sorting:before,
  table.dataTable thead > tr > th.sorting_asc:before,
  table.dataTable thead > tr > th.sorting_desc:before,
  table.dataTable thead > tr > th.sorting_asc_disabled:before,
  table.dataTable thead > tr > th.sorting_desc_disabled:before {
    right: 2em !important;
  }
  table.dataTable thead > tr > th.sorting:after,
  table.dataTable thead > tr > th.sorting_asc:after,
  table.dataTable thead > tr > th.sorting_desc:after,
  table.dataTable thead > tr > th.sorting_asc_disabled:after,
  table.dataTable thead > tr > th.sorting_desc_disabled:after {
    right: 1.1em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 2.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 1.8em !important;
  }
}
@media screen and (max-width: 1024px) {
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting:before {
    right: 3.1em !important;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting:after {
    right: 2.4em !important;
  }
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  div.dataTables_wrapper div.dataTables_filter input {
    width: 80%;
  }
}
@media screen and (max-width: 670px) {
  div.dataTables_wrapper div.dataTables_filter {
    min-width: 100px;
    max-width: 150px;
    width: 40%;
  }
}
@media screen and (max-width: 599px) {
  div.dataTables_wrapper div.dataTables_filter {
    right: 50%;
    margin-right: -106.5px;
    width: 100%;
    max-width: 230px;
  }
  div.dataTables_wrapper div.dataTables_filter input {
    width: 100%;
  }
}
