body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiase;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.8em;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #000 !important;
}

a:hover {
  color: #000 !important;
}

.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row !important;
}

.space-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.mr-2 {
  margin-right: 16px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.p-3 {
  padding: 24px !important;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.full-width {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.pointer {
  cursor: pointer !important;
}

.MuiPopover-paper .MuiMenu-list .MuiMenuItem-root .MuiFormControlLabel-root {
  width: 100% !important;
}

#lang-select .MuiPopover-paper {
  top: 58px !important;
}

/*force datatable color disabled*/
.MuiTypography-colorSecondary {
  color: #666 !important;
}

.hasPermissionWrap {
  display: inline;
}

.MuiDrawer-paper .MuiDivider-root {
  margin-bottom: 0 !important;
}

span.status.subscribed {
  color: #2c3ff3;
  background-color: #2c3ff308 !important;
}

span.status.expired {
  color: #aaa;
  background-color: #dddddd08 !important;
}

span.status.cancelled {
  color: #fac107;
  background-color: #ffda6009 !important;
}

.subscription-service {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.outlinedSelector {
  width: 180px !important;
  display: inline-flex !important;
  border-radius: 4px !important;
  border: 1px solid #ddd !important;
  position: relative;
}

.outlinedSelector .MuiInput-underline:before,
.outlinedSelector .MuiInput-underline:after {
  border: none !important;
}

.outlinedSelector .MuiSelect-select.MuiSelect-select {
  padding-left: 8px !important;
}

.outlinedSelector .MuiChip-root {
  background-color: #e0e0e094 !important;
  margin-right: 5px !important;
  height: initial;
}

.outlinedSelector .default-value {
  position: absolute;
  top: 8px;
  left: 10px;
  color: #ccc;
}

.selectCompanyAndUserWrap .outlinedSelector .default-value {
  top: 12px;
}

.inGridSelectorWrap .outlinedSelector {
  width: 100% !important;
  height: 45px;
}

.inGridSelectorWrap
  .outlinedSelector.singleSelector
  .MuiSelect-select.MuiSelect-select {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 18px !important;
}

.inGridSelectorWrap .outlinedSelector .MuiChip-root {
  height: 32px;
}

.service-icon-name {
  display: inline-flex;
  align-items: center;
  padding: 5px;
}

.service-icon-name img {
  height: 20px;
  margin-right: 5px;
}

.MuiPickersBasePicker-container {
  margin: -32px;
}

.date-time-picker label + .MuiInput-formControl {
  margin-top: 0 !important;
}

.date-time-picker .MuiInputLabel-formControl {
  top: -17px !important;
  font-size: 0.8rem;
  color: #ccc;
}

.users-multiple-selector,
.company-single-selector {
  height: 45px !important;
}

.users-multiple-selector .outlinedSelector .MuiSelect-select.MuiSelect-select {
  height: 45px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.users-multiple-selector
  .outlinedSelector
  .MuiSelect-select.MuiSelect-select
  > div {
  top: 7px;
}

.users-multiple-selector
  .outlinedSelector
  .MuiSelect-select.MuiSelect-select
  .track {
  top: 28px !important;
}

.MuiButton-containedPrimary {
  background-color: #2c3ff3 !important;
}

.MuiTypography-colorPrimary {
  color: #2c3ff3 !important;
}

/*semantic-ui-react*/
.ui.fluid.dropdown {
  height: 46px !important;
}

.ui.search.selection.dropdown > input.search {
  height: 46px !important;
  padding: 0 0 0 15px !important;
}

.ui.search.dropdown > .text {
  top: 4px !important;
}

.ui.fluid.dropdown > .dropdown.icon {
  top: 13px !important;
}

/* Password Related */

.password-input-wrap {
  position: relative;
}

.password-input-wrap
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.password-input-wrap .MuiIconButton-root {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
}

.error-text-field .MuiFormHelperText-contained {
  color: red !important;
}

#os-select-label {
  top: -5px !important;
  left: 15px !important;
}
.paginate_input {
  height: 20px;
  width: 40px;
  margin: 0 10px;
  border-radius: 6px;
}
.paging_input {
  margin-top: 30px;
}
.paginate_button {
  cursor: pointer;
}
.first.paginate_button,
.last.paginate_button {
  color: #999;
}
.first.paginate_button.disabled,
.last.paginate_button.disabled,
.next.paginate_button.disabled,
.previous.paginate_button.disabled {
  cursor: not-allowed;
}
.paginate_total {
  margin-left: 10px;
}
@media screen and (max-width: 990px) {
  .full-width-from-sm {
    width: 100% !important;
  }

  .hide-xs-sm {
    display: none;
  }

  .flex-direction-column-from-sm {
    flex-direction: column;
  }
}
