.ant-tree .ant-tree-treenode-selected:hover::before, 
.ant-tree .ant-tree-treenode-selected::before{
    background: #2d3df30d;
}

.ant-tree .ant-tree-treenode-selected .ant-tree-switcher{
    color: #2c3ff3;
}

.ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected{
    color: #2c3ff3;
    background-color: #fff;
}

.site-tree-search-value{
    color:#f44336;
}