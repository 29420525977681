.avatar-editor {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
}

.avatar-editor .MuiTypography-caption{
    display: block;
}

.avatar-editor .MuiTypography-colorSecondary{
    color: #9e9e9e;
}

input{
    z-index: 2;
    position: relative;
    margin-right: 20px;
    width: 80px;
    height: 80px;
    /* cursor: pointer; */
}

input::-webkit-file-upload-button {
    visibility: hidden;
    width: 80px;
    z-index: 2;
}

input::before{
    content: '';
    width: 80px;
    height: 80px;
    display: block;
    position: relative;
}

.avatar-image{
    position: absolute;
    top: 0;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-image img{
    width: 100%;
    display: block;
}

  
 